// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

@import 'functions';

$primary: #2E55E0;
$secondary: #FF7756;
$tertiary: #7BCDD9;
$neutral: #1B1B1B;

:root {
  --ion-color-primary: #2E55E0;
  --ion-color-primary-rgb: 46, 85, 224;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2a4dcc;
  --ion-color-primary-shade-contrast: #ffffff;
  --ion-color-primary-shade-contrast-rgb: 255, 255, 255;
  --ion-color-primary-tint: #4367e3;
  --ion-item-background: var(--color-neutral-1); /* Use your Tailwind color */

  /* --ion-color-primary: #5877E6;
	--ion-color-primary-rgb: 88,119,230;
	--ion-color-primary-contrast: theme('colors.neutral.1');
  --ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #4d69ca;
	--ion-color-primary-tint: #6985e9; */

  /* --ion-color-primary: theme('colors.primary.800');
  --ion-color-primary-rgb: 25, 47, 123;
  --ion-color-primary-contrast: theme('colors.neutral.1');
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #16296c;
	--ion-color-primary-tint: #304488; */

  --ion-color-secondary: theme('colors.secondary.500');
  --ion-color-secondary-rgb: 255, 119, 86;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: theme('colors.secondary.700');
  --ion-color-secondary-tint: theme('colors.secondary.600');

  --ion-color-tertiary: theme('colors.tertiary.500');
  --ion-color-tertiary-rgb: to-rgb(theme('colors.tertiary.500'));
  --ion-color-tertiary-contrast: $neutral;
  --ion-color-tertiary-contrast-rgb: #{to-rgb($neutral)};
  --ion-color-tertiary-shade: theme('colors.tertiary.700');
  --ion-color-tertiary-tint: theme('colors.tertiary.600');

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #f44336;
  --ion-color-danger-rgb: 244, 67, 54;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d50000;
  --ion-color-danger-tint: #d50000;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: theme('colors.neutral.13');
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: theme('colors.neutral.12');
  --ion-color-dark-tint: theme('colors.neutral.9');

  --ion-font-family: theme('fontFamily.sans');
  --ion-text-color: theme('colors.neutral.12');
}
