@layer base {
  html {
    @apply text-neutral-12;
  }

  .covent-bg-gradient {
    // @apply bg-[radial-gradient(ellipse_at_center,rgba(123,205,217,0.07)_0%,rgba(46,85,224,0.07)_53.5%,rgba(255,119,86,0.07)_91.5%,rgba(255,119,86,0)_100%)];
    // @apply bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-[#ebefff] via-primary-100/30 to-secondary-50/60;
  }
}

@layer components {

    .scrollbar-hide {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
      }
    
      .scrollbar-hide::-webkit-scrollbar {
        display: none; /* Webkit browsers */
      }

  ion-button {
    @apply m-0;

    &.ion-color-primary.ion-activated {
      @apply text-neutral-1;
    }
  }

  .alert-button {
    color: theme('colors.primary.500') !important;
  }

  button.alert-button.alert-button-danger {
    color: theme("colors.red.500") !important;
  }

  ion-datetime-button,
  ion-datetime {
    --ion-color-primary: theme('colors.primary.500');
  }

  ion-toolbar {
    ion-input.component-title-input.edit-mode {
      @apply w-full bg-neutral-4 border border-neutral-3 rounded-lg text-sm;
      min-height: theme("minHeight.9") !important;
      padding-left: theme("padding.2") !important;
      padding-right: theme("padding.2") !important;
    }
  }

  .toolbar-button {
    @apply part-native:hover:text-primary-500 hover:opacity-100;
  }

  .covent-scrollbar {
    @apply part-scroll:scrollbar-thin scrollbar-thumb-neutral-4 scrollbar-thumb-rounded-full scrollbar-track-transparent hover:scrollbar-thumb-neutral-5;
    scrollbar-width: thin;
  }

  .covent-default-scrollbar {
    @apply scrollbar-thin scrollbar-thumb-neutral-4 scrollbar-track-transparent scrollbar-thumb-rounded-full hover:scrollbar-thumb-neutral-5;
    scrollbar-width: thin;
  }

  ion-content::part(scroll) {
    scrollbar-width: thin !important;
  }

  ion-input {
    input {
      caret-color: theme('colors.neutral.8') !important;
    }

    &.ng-touched.ng-invalid {
      input {
      caret-color: theme('colors.red.500') !important;
      }
    }
  }

  .covent-form-field {
    @apply w-full flex flex-col;

    .covent-form-fields {
      @apply flex items-center gap-2;
    }

    ion-label {
      @apply text-xs mb-1;
    }

    ion-input,
    input {
      @apply w-full bg-neutral-4 border border-neutral-3 rounded-lg min-h-9 text-sm;
    }

    ion-input {
      input {
        @apply outline-none ring-0 caret-black;
        caret-color: black !important;
      }
    }

    input,
    textarea {
      @apply outline-none focus:outline-none focus:ring-0;
    }

    ion-textarea {
      @apply w-full bg-neutral-4 border border-neutral-3 rounded-lg min-h-9 text-sm py-2;
    }

    tag-input {
      .ng2-tag-input {
        @apply p-1 border border-neutral-6 rounded-cmd min-h-9 bg-neutral-1;

        .ng2-tags-container {
          @apply items-center gap-1;

          tag {
            @apply h-6 font-medium text-xs leading-5 pt-0.5 my-0 rounded-lg pr-0 text-primary-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-500 focus:bg-primary-400 focus:text-neutral-1;
            box-shadow: none !important;

            .tag-wrapper {
              @apply items-center;

              .tag__text {
                letter-spacing: normal;
              }

              delete-icon {
                height: 11px !important;
                transform: none;

                svg {
                  height: 12px !important;
                  stroke: theme("colors.primary.100");
                  stroke-width: 3px;
                }
              }
            }

            &:hover {
              .tag-wrapper {
                delete-icon {
                  svg {
                    stroke: theme("colors.primary.300");
                  }
                }
              }
            }

            &:focus {
              .tag-wrapper {
                delete-icon {
                  svg {
                    stroke: theme("colors.white");
                  }
                }
              }
            }
          }

          tag-input-form {
            input {
              @apply text-sm font-medium h-7 min-h-7 bg-neutral-1 ring-neutral-6 rounded-lg;
            }
          }
        }

        &.ng2-tag-input--focused {
          @apply border-b border-neutral-3;
        }
      }
    }
  }

  .bordered-input {
    @apply bg-neutral-4 border border-neutral-3 rounded-cmd w-96 min-h-9 text-sm;
  }

  .search-input {
    @apply bg-neutral-4 border border-neutral-3 rounded-cmd w-96 min-h-9 text-sm caret-neutral-8;
  }

  ion-content {
    @apply covent-scrollbar;
  }

  .event-detail-menu,.sponsor-detail-menu {
    &.__min {
      @apply [--width:547px] lg:[--width:547px];
    }
    &.__max {
      @apply [--width:100%] lg:[--width:90%] lg:[--min-width:1280px] [--max-width:1620px];
    }

    &::part(container) {
      @apply bg-transparent;
    }
  }

  .sponsor-overview-menu {
    @apply [--width:500px] lg:[--width:500px];
    &::part(container) {
      @apply bg-transparent;
    }
  }

  .event-detail-menu {
    -webkit-transition: -webkit-transform 100ms ease;
    -moz-transition: -moz-transform 100ms ease;
    transition: transform 100ms ease;
  }

  .iframe-pages-menu {
    @apply [--width:100%] lg:[--width:90%] lg:[--min-width:1280px];
  }

  .covent-datetime-button {
    @apply part-native:bg-neutral-4 part-native:rounded-cmd rounded-cmd border border-neutral-3 part-native:text-sm part-native:m-0 part-native:w-full part-native:text-left;
    &::part(native) {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  ion-textarea {
    textarea {
      caret-color: theme('colors.neutral.12') !important;
    }
  }
}


// animated button
@layer components {
  .animated-border-box,
  .animated-border-box-glow {
    @apply h-full w-full absolute overflow-hidden z-0 rounded-cmd;
  }

  .animated-border-box-glow {
    @apply overflow-hidden blur-[2px];
  }

  .animated-border-box::before,
  .animated-border-box-glow::before {
    content: "";
    @apply z-[-2] absolute top-1/2 left-1/2 w-[99999px] h-[99999px] bg-no-repeat bg-[0_0];
    transform: translate(-50%, -50%) rotate(0deg);
    /* background-image: conic-gradient(
      rgba(253, 139, 73, 0.548),
      #1976ed,
      rgb(255, 255, 255) 25%
    ); */
    animation: rotate 3s linear infinite;
  }

  .animated-border-box::after {
    content: "";
    @apply absolute z-[-1] left-[3px] top-[3px] w-[calc(100%-6px)] h-[calc(100%-6px)] bg-neutral-1 rounded-[7px];
  }

  .ai-bg-gradient:before {
    background-image: conic-gradient(
      rgba(253, 139, 73, 0.548),
      #1976ed,
      rgb(255, 255, 255) 25%
    );
  }

  .manual-bg-gradient:before {
    background-image: conic-gradient(
      rgba(100, 100, 100, 0.548),
      #494949,
      rgb(255, 255, 255) 25%
    );
  }
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

.btn-primary {
  @apply h-9 rounded-full text-sm bg-tertiary-50 hover:bg-tertiary-100 border-2 border-solid border-tertiary-200 text-tertiary-900 px-5;
}

.btn-primary-current {
  @apply h-9 rounded-full text-sm bg-tertiary-50 text-tertiary-900 px-5;
}

.btn-secondary {
  @apply h-9 rounded-md text-neutral-1 bg-blue-500 hover:bg-blue-600 px-4;
}

.subheader {
  @apply font-sans font-normal text-2xl
}

.header {
  @apply font-sans font-medium text-[44px] leading-normal
}

.pricing-header {
  @apply font-sans font-medium text-[32px] leading-normal
}

@layer components {
  .hover-card {
    @apply border border-primary-100 bg-transparent hover:bg-primary-50 text-primary-900 p-4 rounded-[32px] transition duration-300 ease-in-out h-full  w-[338px] h-[539px];
  }
}

@layer components {
  .action-tooltip-wrapper:hover .action-tooltip {
    @apply opacity-100;
  }

  .action-tooltip {
    @apply cursor-pointer bg-neutral-2 text-neutral-8 py-1 px-2 rounded-md text-sm text-center absolute whitespace-nowrap z-10 opacity-0 transition-opacity duration-300;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

}


@layer components {
  .custom-gradient {
    border: solid 1px theme('colors.tertiary.500');
    background: linear-gradient(90deg, rgba(68,113,119,1) 0%, rgba(123,205,217,1) 64%, rgba(167,222,230,1) 100%);
  }
}
@layer components {
  /* Standard Buttons */
  .btn {
    @apply inline-flex justify-center items-center gap-2.5 px-4 py-1 rounded-full transition-colors;
  }

  .btn-lazuli {
    @apply btn bg-primary-500 text-neutral-1 hover:bg-primary-400 active:bg-primary-600;
  }

  .btn-lazuli-border {
    @apply btn border-solid border-2 border-primary-200 bg-primary-50 text-neutral-9 hover:border-primary-300 hover:bg-primary-100 active:border-primary-400 active:bg-primary-200;
  }

  .btn-moonstone {
    @apply btn bg-tertiary-50 text-neutral-9 hover:bg-tertiary-100 active:bg-tertiary-200;
  }

  .btn-inactive {
    @apply btn bg-neutral-3 text-neutral-7;
  }

  .btn-moonstone-border {
    @apply btn border-solid border-2 border-tertiary-200 bg-tertiary-50 text-neutral-9 hover:border-tertiary-300 hover:bg-tertiary-100 active:border-tertiary-400 active:bg-tertiary-200;
  }

  /* Radio Buttons */
  .covent-radio::part(container) {
    width: 13px;
    height: 13px;
  }

  .covent-radio::part(mark) {
    width: 7px; /* Adjust the size of the inner mark */
    height: 7px;
  }

  /* Popover */
  .persona-popover {
    --width: 185px;
    right: 5px;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}