:root {
  /* Neutral Colors */
  --color-neutral-1: #ffffff;
  --color-neutral-2: #fdfdfd;
  --color-neutral-3: #f5f5f5;
  --color-neutral-4: #f0f0f0;
  --color-neutral-5: #dadada;
  --color-neutral-6: #c1c1c1;
  --color-neutral-7: #8f8f8f;
  --color-neutral-8: #5e5e5e;
  --color-neutral-9: #4a4a4a;
  --color-neutral-10: #2c2c2c;
  --color-neutral-11: #252525;
  --color-neutral-12: #1b1b1b;
  --color-neutral-13: #070707;

  /* Primary Colors */
  --color-primary-50: #eaeefc;
  --color-primary-100: #becaf5;
  --color-primary-200: #9fb1f1;
  --color-primary-300: #738dea;
  --color-primary-400: #5877e6;
  --color-primary-500: #2e55e0;
  --color-primary-600: #2a4dcc;
  --color-primary-700: #213c9f;
  --color-primary-800: #192f7b;
  --color-primary-900: #13245e;

  /* Secondary Colors */
  --color-secondary-50: #fff1ee;
  --color-secondary-100: #ffd5cb;
  --color-secondary-200: #ffc0b1;
  --color-secondary-300: #ffa48e;
  --color-secondary-400: #ff9278;
  --color-secondary-500: #ff7756;
  --color-secondary-600: #e86c4e;
  --color-secondary-700: #b5543d;
  --color-secondary-800: #8c412f;
  --color-secondary-900: #6b3224;

  /* Tertiary Colors */
  --color-tertiary-50: #f2fafb;
  --color-tertiary-100: #d6f0f3;
  --color-tertiary-200: #c2e8ee;
  --color-tertiary-300: #a7dee6;
  --color-tertiary-400: #95d7e1;
  --color-tertiary-500: #7bcdd9;
  --color-tertiary-600: #70bbc5;
  --color-tertiary-700: #57929a;
  --color-tertiary-800: #447177;
  --color-tertiary-900: #34565b;
}

.dark {
  /* Inverse Dark Mode Colors */
  --color-neutral-1: #070707;
  --color-neutral-2: #1b1b1b;
  --color-neutral-3: #252525;
  --color-neutral-4: #2c2c2c;
  --color-neutral-5: #4a4a4a;
  --color-neutral-6: #5e5e5e;
  --color-neutral-7: #8f8f8f;
  --color-neutral-8: #c1c1c1;
  --color-neutral-9: #dadada;
  --color-neutral-10: #f0f0f0;
  --color-neutral-11: #f5f5f5;
  --color-neutral-12: #fdfdfd;
  --color-neutral-13: #ffffff;

  /* Primary (Inverted) */
  --color-primary-50: #13245e;
  --color-primary-100: #192f7b;
  --color-primary-200: #213c9f;
  --color-primary-300: #2a4dcc;
  --color-primary-400: #2e55e0;
  --color-primary-500: #5877e6;
  --color-primary-600: #738dea;
  --color-primary-700: #9fb1f1;
  --color-primary-800: #becaf5;
  --color-primary-900: #eaeefc;

  /* Secondary (Inverted) */
  --color-secondary-50: #6b3224;
  --color-secondary-100: #8c412f;
  --color-secondary-200: #b5543d;
  --color-secondary-300: #e86c4e;
  --color-secondary-400: #ff7756;
  --color-secondary-500: #ff9278;
  --color-secondary-600: #ffa48e;
  --color-secondary-700: #ffc0b1;
  --color-secondary-800: #ffd5cb;
  --color-secondary-900: #fff1ee;

  /* Tertiary (Inverted) */
  --color-tertiary-50: #34565b;
  --color-tertiary-100: #447177;
  --color-tertiary-200: #57929a;
  --color-tertiary-300: #70bbc5;
  --color-tertiary-400: #7bcdd9;
  --color-tertiary-500: #95d7e1;
  --color-tertiary-600: #a7dee6;
  --color-tertiary-700: #c2e8ee;
  --color-tertiary-800: #d6f0f3;
  --color-tertiary-900: #f2fafb;
}

ion-toolbar {
  --min-height: 64px;
  --background: theme("colors.neutral.1");
  --border-width: 0 !important;

  ion-button {
    --opacity: 1;
  }

  &.page-toolbar {
    --background: theme("colors.neutral.1");
    --border-width: 0 0 1px 0 !important;
    --border-color: theme("colors.neutral.5");
  }
}

ion-item.bordered {
  --inner-padding-end: 0;
  --border-style: none;
}

ion-input.bordered {
  --background: theme("colors.neutral.1");
  --placeholder-color: theme("colors.neutral.7");
  --placeholder-opacity: 0.4;

  --padding-bottom: 9px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 9px;
  border-radius: theme("borderRadius.cmd");

  .input-wrapper {
    border-radius: 8px;

    --padding-top: 2px;
    --padding-bottom: 2px;
  }
}

ion-checkbox {
  --border-color: theme("colors.neutral.6");
  --border-width: 1px;
  --checkbox-background: theme("colors.neutral.2");

  &::part(label) {
    white-space: normal;
  }

  &.small-checkbox {
    &::part(label) {
      @apply ml-2;
    }
  }
}

ion-radio.outlined-radio {
  &::part(container) {
    @apply w-3 h-3 border border-solid border-neutral-8 rounded-full;
  }

  &::part(label) {
    @apply ml-2;
  }
}

ion-button.action-button {
  --border-radius: theme("borderRadius.cmd");
  min-height: 2.875rem;
}

ion-textarea {
  .native-textarea {
    --padding-top: 0;
    --padding-bottom: 0;
  }
}

ion-loading.covent-loading {
  --spinner-color: theme(colors.primary.500);
  --backdrop-opacity: 0.8;
  --background: theme(colors.neutral.1);
  ion-backdrop {
    background-color: theme("colors.neutral.1");
  }
  .loading-wrapper {
    flex-direction: column;
    align-items: center;

    .loading-spinner {
      margin-bottom: 10px;

      ion-spinner {
        width: 48px;
        height: 48px;
      }
    }

    .loading-content {
      font-weight: 500;
    }
  }
}

// Modal
ion-modal.search-modal {
  --width: 64.313rem;
  --min-width: 64.313rem;
  --height: 46.563rem;
  --border-radius: 1.25rem;
  --backdrop-opacity: theme("opacity.50");
  --box-shadow: rgba(0, 0, 0, 0.1) 0 0 60px;

  &::part(backdrop) {
    background-color: theme("colors.neutral.2");
  }

  ion-toolbar {
    --background: theme("colors.neutral.3");
  }
}

ion-modal.update-checker-modal {
  --width: fit-content;
  --min-width: 400px;
  --height: 220px;
  --border-radius: 6px;
  --box-shadow: theme("boxShadow.lg");

  ion-toolbar {
    ion-title {
      @apply text-left pl-4;
    }
  }
}

ion-modal.organizer-upgrade-modal {
  --width: 1150px;
  --min-width: 764px;
  --height: 90%;
  --min-height: 600px;
  --border-radius: 6px;
  --box-shadow: theme("boxShadow.lg");
}

ion-modal.pre-publish-event-modal {
  @apply sm:[--width:1150px] sm:[--min-width:764px] [--width:calc(100%-24px)] [--min-width:calc(100%-24px)];
  --height: 90%;
  --min-height: 600px;
  --border-radius: 10px;
  --box-shadow: theme("boxShadow.lg");
}

ion-modal.sponsor-form-modal {
  --width: calc(100% - 24px);
  --min-width: calc(100% - 24px);
  --height: calc(100% - 24px);
  --border-radius: 10px;
  --box-shadow: theme("boxShadow.lg");

  ion-toolbar {
    ion-title {
      @apply text-left pl-4;
    }
  }
}

.sponsor-view-detail-modal {
  --width: calc(100% - 24px);
  --min-width: calc(100% - 24px);
  --height: calc(100% - 16px);
  --border-radius: 10px;
  --box-shadow: theme("boxShadow.lg");
  --backdrop-opacity: 0;
  @apply ml-16;
}

.detail-upgrade-modal {
  --width: 711px;
  --min-width: 711px;
  --height: 464px;
  --border-radius: 10px;
  --box-shadow: theme("boxShadow.lg");
}

.detail-persona-upgrade-modal {
  --width: 711px;
  --min-width: 711px;
  --height: 464px;
  --border-radius: 10px;
  --box-shadow: theme("boxShadow.lg");
}

.upgrade-mmeting-schedule-modal {
  --width: calc(100% - 100px);
  --min-width: calc(100% - 100px);
  --height: calc(100% - 75px);
  --border-radius: 10px;
  --box-shadow: theme("boxShadow.lg");
}

.browser-compatibility-modal {
  --width: 100%;
  --min-width: 100%;
  --height: 186px;
  --border-radius: 0;
  --box-shadow: theme("boxShadow.lg");
}

// Split Pane
ion-split-pane {
  --side-width: 240px;
  --side-max-width: 240px;
  --border: 1px solid theme("colors.neutral.2");

  ion-menu {
    border-right: 1px solid theme("colors.neutral.2");
  }

  &.minimized {
    --side-width: 64px;
    --side-max-width: 64px;

    ion-menu {
      --width: 64px;
      --side-min-width: 64px;
    }
  }
}

// Popover
ion-popover {
  --max-height: theme("height.96");

  .new-search-list {
    ion-item {
      --min-height: 2.25rem;

      &:last-child {
        --border-style: none;
      }
    }
  }
}

.profile-popover,
.header-profile-popover {
  /* right: calc(100vw - 300px); */

  .profile-menu-list {
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-top: 0;
    margin-bottom: 0;

    ion-item {
      --background: transparent;
    }
  }
}

.profile-popover {
  --width: 238px;
  --offset-y: -12px;
  --box-shadow: theme(boxShadow.none);
  --offset-x: 10px;
}

.contact-organizer-popover {
  --width: 452px;
  --offset-y: 12px;
  --box-shadow: theme(boxShadow.none);
  --offset-x: -42px;
  --backdrop-opacity: 0.3;
}

.header-profile-popover {
  --width: 280px;
  --offset-y: 2px;
}

.share-popover,
.report-inaccuracy-popover {
  --width: 480px;
  --box-shadow: theme(boxShadow.none);
}

.covent-dropdown-popover {
  --width: 250px;
  --offset-y: 8px;
  --box-shadow: 0px 0px 10px 0px #0000001a;
  --max-height: 506px;
}
/* 
.report-inaccuracy-popover {
  --height: 350px;
} */

// Input
ion-input.search-input {
  .native-input {
    caret-color: theme("colors.neutral.8") !important;
  }

  .label-text-wrapper {
    margin-inline-end: 0.625rem !important;
  }
  ion-icon {
    margin-inline-end: 0.2rem !important;
  }
}

// ion-toggle {
//   padding: 12px;

//   --handle-width: 25px;
//   --handle-height: 27px;
//   --handle-max-height: auto;
//   --handle-spacing: 6px;

//   contain: none;
// }

// ion-toggle::part(track),
// ion-toggle.toggle-checked::part(track) {
//   height: 10px;
//   width: 65px;

//   background: #ddd;

//   /* Required for iOS handle to overflow the height of the track */
//   overflow: visible;
// }

// ion-toggle::part(handle) {
//   background: white;

//   border-radius: 50px;
//   box-shadow: none;
// }

ion-toggle::part(track) {
  background: grey;
  height: 20px;
  // width: 32px;
  overflow: visible;
}

ion-toggle.toggle-checked::part(track) {
  background: #67a775;

  /* Required for iOS handle to overflow the height of the track */
}

ion-toggle {
  --handle-border-radius: 50%; /* Ensures a round handle */
  --handle-width: 18px;
  --handle-height: 18px;
  --border-radius: 20px; /* Rounded edges */
  --background: #ddd; /* Gray background when off */
}

.covent-form-field {
  ion-input,
  ion-textarea {
    --padding-start: 0.75rem;
    @apply min-h-[2.375rem];
  }

  tag-input .ng2-tag-input {
    @apply min-h-10;
  }

  ion-datetime-button::part(native) {
    @apply min-h-[2.375rem];
  }
}

//ng2-dropdown-menu
ng2-dropdown-menu {
  .ng2-dropdown-menu {
    padding: 0 !important;
    max-height: 200px !important;
    @apply covent-default-scrollbar rounded-cmd shadow-md;

    ng2-menu-item {
      .ng2-menu-item {
        @apply h-8 text-neutral-8 text-sm leading-tight px-4 pb-1 pt-2 hover:bg-primary-50;
      }
    }
  }
}

input,
textarea {
  outline: none !important;
  border: none !important;
  --tw-ring-color: transparent !important;
}

.component-title-input {
  input,
  textarea {
    outline: none !important;
    border: none !important;
    --tw-ring-color: transparent !important;
  }
}

.covent-social-media-embed {
  .instagram-post iframe.instagram-media {
    width: 100%;
  }
}

// TypeForm
.tf-v1-widget {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: theme("colors.neutral.3");
  border-radius: theme("borderRadius.full");
}

* {
  scrollbar-width: thin;
}

ion-popover {
  @apply part-content:covent-default-scrollbar;
}

.covent-otp-input {
  width: theme("width.full") !important;
  height: theme("height.14") !important;
  border: 1px solid theme("colors.neutral.3") !important;
  font-size: theme("fontSize.2xl") !important;
  background-color: theme("colors.neutral.2");

  &:focus {
    border: 1px solid theme("colors.primary.500") !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  appearance: none !important;
  -moz-appearance: textfield !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px theme("colors.neutral.3") inset;
  box-shadow: 0 0 0px 1000px theme("colors.neutral.3") inset;
  transition: background-color 5000s ease-in-out 0s;
}
